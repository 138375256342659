import {Container, Skeleton} from "@mui/material";
import AppTemplate from "../../../components/Templates/App/App";
import LoadingAppTemplate from "../../../components/Templates/LoadingApp/LoadingApp";
import BackArrow from "../../../components/Atoms/BackArrow/BackArrow";
import Title from "../../../components/Atoms/Title/Title";
import Text from "../../../components/Atoms/Text/Text";

interface IContractSigningLoadingSkeleton {
    withRealNavBar?: boolean
};

const ContractSigningLoadingSkeleton = ({ withRealNavBar }: IContractSigningLoadingSkeleton): JSX.Element => {

    const Content = (): JSX.Element => {
        return (
            <Container maxWidth="sm">
                <BackArrow disabled={true}/>

                <Title
                    text={<Skeleton variant="text" width={'55%'} height={24} />}
                    type="primary"
                />

                <Container maxWidth="sm">
                    <div className="skeleton mt-10 contact__signing__skeleton__item">
                        <div style={ {width: '25px', alignSelf: 'flex-start', height: '20px'} }>
                            <Skeleton variant="circular" width={'100%'} height={25} />
                        </div>

                        <div style={ {width: 'calc(100% - 40px)', alignSelf: 'flex-start', height: '60px'} }>
                            <Skeleton variant="text" width={'90%'} className="mb-0" height={20} />
                            <Skeleton variant="text" width={'100%'} className="mb-0" height={20} />
                            <Skeleton variant="text" width={'30%'} className="mb-0" height={20} />
                        </div>
                    </div>

                    <div className="skeleton mt-10 contact__signing__skeleton__item mt-30">
                        <div style={ {width: '25px', alignSelf: 'flex-start', height: '20px'} }>
                            <Skeleton variant="circular" width={'100%'} height={25} />
                        </div>

                        <div style={ {width: 'calc(100% - 40px)', alignSelf: 'flex-start', height: '60px'} }>
                            <Skeleton variant="text" width={'100%'} className="mb-0" height={20} />
                            <Skeleton variant="text" width={'60%'} className="mb-0" height={20} />
                        </div>
                    </div>
                </Container>

                <Container maxWidth="sm">
                    <div className="skeleton-center mt-30">
                        <Skeleton style={ { borderRadius: '12px' } } variant="rectangular" width={'100%'} height={35} />
                    </div>
                </Container>

                <Container maxWidth="sm">
                    <div className="skeleton-center mt-30">
                        <Skeleton style={ { borderRadius: '12px' } } variant="rectangular" width={'100%'} height={35} />
                    </div>
                </Container>

            </Container>
        );
    };

    return (
        <>
            {
                withRealNavBar
                    ? (
                        <AppTemplate>
                            <Content />
                        </AppTemplate>
                    )
                    : (
                        <LoadingAppTemplate>
                            <Content />
                        </LoadingAppTemplate>
                    )
            }
        </>
    );
};

export default ContractSigningLoadingSkeleton;