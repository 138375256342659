import {Container, Skeleton} from "@mui/material";
import React from "react";
import AppTemplate from "../../../components/Templates/App/App";
import LoadingAppTemplate from "../../../components/Templates/LoadingApp/LoadingApp";
import BackArrow from "../../../components/Atoms/BackArrow/BackArrow";

interface IMyContractLoadingSkeleton {
    withRealNavBar?: boolean
};

const MyContractLoadingSkeleton = ({ withRealNavBar }: IMyContractLoadingSkeleton): JSX.Element => {
    const Content = (): JSX.Element => {
        return (
            <Container maxWidth="sm">
                <BackArrow disabled={true}/>

                <Container maxWidth="sm">
                    <div className="skeleton-center mt-20">
                        <Skeleton style={ { borderRadius: '10px' } } variant="rectangular" width={'100%'} height={140} />
                        <Skeleton style={ { borderRadius: '10px' } } className="mt-20" variant="rectangular" width={'100%'} height={140} />
                    </div>
                </Container>
            </Container>
        );
    };

    return (
        <>
            {
                withRealNavBar
                    ? (
                        <AppTemplate>
                            <Content />
                        </AppTemplate>
                    )
                    : (
                        <LoadingAppTemplate>
                            <Content />
                        </LoadingAppTemplate>
                    )
            }
        </>
    );
};

export default MyContractLoadingSkeleton;