import Title from "../../../components/Atoms/Title/Title";
import {Container, Skeleton} from "@mui/material";
import Text from "../../../components/Atoms/Text/Text";
import LoadingAppTemplate from "../../../components/Templates/LoadingApp/LoadingApp";
import React from "react";
import AppTemplate from "../../../components/Templates/App/App";
import BackArrow from "../../../components/Atoms/BackArrow/BackArrow";

interface IMyTaskLoadingSkeleton {
    withRealNavBar?: boolean
};

const MyTaskLoadingSkeleton = ({ withRealNavBar }: IMyTaskLoadingSkeleton): JSX.Element => {

    const Content = (): JSX.Element => {
        return (
            <Container maxWidth="sm">
                <BackArrow disabled={true}/>
                <Title
                    text={<Skeleton variant="text" width={'55%'} height={24} />}
                    type="primary"
                />
                <Text
                    text={
                        <>
                            <Skeleton variant="text" width={'100%'} height={18} />
                            <Skeleton variant="text" width={'60%'} height={18} />
                        </>
                    }
                />
                <Container maxWidth="sm">
                    <div className="skeleton-center flex-container">
                        <Skeleton variant="text" width={'35%'} height={25} />
                        <Skeleton variant="text" width={'35%'} height={25} />
                    </div>
                    <div className="skeleton-center flex-container">
                        <Skeleton variant="rectangular" width={'45%'} height={135} />
                        <Skeleton variant="rectangular" width={'45%'} height={135} />
                    </div>
                </Container>
            </Container>
        );
    };

    return (
        <>
            {
                withRealNavBar
                    ? (
                        <AppTemplate>
                            <Content />
                        </AppTemplate>
                    )
                    : (
                        <LoadingAppTemplate>
                            <Content />
                        </LoadingAppTemplate>
                    )
            }
        </>
    );
};

export default MyTaskLoadingSkeleton;