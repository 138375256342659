
import { Container } from '@material-ui/core';
import './Title.scss';

interface TitleInterface {
  className?: string;
  disabled?: boolean;
  large?: boolean;
  text: string | React.ReactNode;
  type: string;
};

const Title = ({
  className,
  disabled = false,
  large = false,
  text,
  type,
  ...rest
}: TitleInterface) => {
  if (className) className = ` ${className}`;

  return large ? (
    <p
      className={`title${type === 'primary' ? ' title--primary ' : ' title--secondary '}${disabled ? 'title--disabled' : ''}${className ? className : ''}`}
      {...rest}
    >
      {text}
    </p>
  ) : (
    <Container maxWidth="sm">
      <p
        className={`title title--${type} ${disabled ? 'title--disabled' : ''}${className}`}
        {...rest}
      >
        {text}
      </p>
    </Container>
  );
};

export default Title;