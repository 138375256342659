import AppTemplate from "../../../components/Templates/App/App";
import LoadingAppTemplate from "../../../components/Templates/LoadingApp/LoadingApp";
import React from "react";
import Title from "../../../components/Atoms/Title/Title";
import {Container, Skeleton} from "@mui/material";
import Text from "../../../components/Atoms/Text/Text";
import BackArrow from "../../../components/Atoms/BackArrow/BackArrow";

interface ICertificationModuleLoadingSkeleton {
    withRealNavBar?: boolean
};

const CertificationModuleLoadingSkeleton = ({ withRealNavBar }: ICertificationModuleLoadingSkeleton): JSX.Element => {

    const Content = (): JSX.Element => {
        return (
            <Container maxWidth="sm">
                <BackArrow disabled={true}/>

                <Title
                    text={<Skeleton variant="text" width={'55%'} height={24} />}
                    type="primary"
                />
                <Text
                    text={
                        <>
                            <Skeleton variant="text" width={'90%'} height={20} />
                            <Skeleton variant="text" width={'30%'} height={20} />
                        </>
                    }
                />

                <Text
                    text={
                        <>
                            <Skeleton variant="text" width={'100%'} height={15} />
                            <Skeleton variant="text" width={'60%'} height={15} />
                        </>
                    }
                />
                <Container maxWidth="sm">
                    <div className="skeleton-center">
                        <Skeleton className="mb-20 mt-20" variant="rectangular" width={'100%'} height={90} />
                        <Skeleton className="mb-20 mt-20" variant="rectangular" width={'100%'} height={90} />
                        <Skeleton className="mb-20 mt-20" variant="rectangular" width={'100%'} height={90} />
                    </div>
                </Container>
            </Container>
        );
    };

    return (
        <>
            {
                withRealNavBar
                    ? (
                        <AppTemplate>
                            <Content />
                        </AppTemplate>
                    )
                    : (
                        <LoadingAppTemplate>
                            <Content />
                        </LoadingAppTemplate>
                    )
            }
        </>
    );
};

export default CertificationModuleLoadingSkeleton;