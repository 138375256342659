import LoadingNavbar from "../../Organisms/LoadingNavbar/LoadingNavbar";

import './LoadingApp.scss';

interface ILoadingApp {
    children: React.ReactNode;
};

const LoadingAppTemplate = ({ children }: ILoadingApp): JSX.Element => {
    return (
        <div className="app__main">
            <LoadingNavbar />
            <div className="app__content">
                {children}
            </div>
        </div>
    );
};

export default LoadingAppTemplate;