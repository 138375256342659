import api from "../utils/axios";
import { formattingTitaskBffResponseErrors} from "../utils/utils";
import Country from "../models/Country";
import City from "../models/City";
import nationalities from "../res/json/nationalities.json";
import countries from "../res/json/countries.json";
import Nationality from "../models/Nationality";
import BffApiResponse from "../models/BffApiResponse";


const CountryService = {
    getCountryDetails: async (countryCode: string): Promise<BffApiResponse> => {
        try {
            const response = await api.get(`/country/${countryCode}`);
            const country: Country = {
                code: response.data.data.code,
                name: response.data.data.name,
                alpha3Code: response.data.data.alpha3Code,
                dialCode: response.data.data.dialCode,
                language: response.data.data.language.trim().substring(0, 2).toLowerCase(),
                specificLanguage: response.data.data.language.trim(),
                termsFileUrl: response.data.data.termsFileUrl,
                aditionalData: response.data.data.aditionalData,
                flag: `/imgs/flags/4x3/${response.data.data.code.toLowerCase()}.svg`
            };

            return new BffApiResponse(true, country);
        } catch (err) {
            return formattingTitaskBffResponseErrors(err);
        }
    },
    getAvailableCountries: async (): Promise<BffApiResponse> => {

        try {
            const response = await api.get(`/country`);
            const countries: Country[] = response.data.data.map((country: any) => {
                return {
                    code: country.code,
                    name: country.name,
                    alpha3Code: country.alpha3Code,
                    dialCode: country.dialCode,
                    language: country.language.trim().substring(0, 2).toLowerCase(),
                    specificLanguage: country.language.trim(),
                    termsFileUrl: country.termsFileUrl,
                    aditionalData: country.aditionalData,
                    flag: `/imgs/flags/4x3/${country.code.toLowerCase()}.svg`
                }
            });

            return new BffApiResponse(true, countries);
        } catch (err) {
            return formattingTitaskBffResponseErrors(err);
        }
    },
    getCitiesByCountry: async (countryCode: string): Promise<BffApiResponse> => {
        try {
            const response = await api.get(`/country/cities/${countryCode}`);
            const cities: City[] = response.data.data.map((city: any) => {
                return {
                    id: city.id,
                    regionId: city.regionId,
                    regionName: city.regionName,
                    provinceId: city.provinceId,
                    provinceName: city.provinceName,
                    cityId: city.cityId,
                    cityName: city.cityName
                };
            });

            return new BffApiResponse(true, cities);
        } catch (err) {
            return formattingTitaskBffResponseErrors(err);
        }
    },
    getNationalityByCountryCode: (countryCodeAlpha3: string): Nationality => {
        const country = countries.find((country: any) => country.alpha3.toLowerCase() === countryCodeAlpha3.toLowerCase());

        if(!country) {
            return {countryCode: '', en: 'Unknown nationality', es: 'Nacionalidad desconocida', alpha3: 'countryCode'};
        }

        const nationality: Nationality | undefined =  nationalities.find((nationality: Nationality) => nationality.countryCode.toUpperCase() === country.alpha2.toUpperCase());

        if(!nationality) {
            return {countryCode: '', en: 'Unknown nationality', es: 'Nacionalidad desconocida', alpha3: 'countryCode'};
        }

        return nationality;
    },
    getCountryAlpha2ByAlpha3(countryCodeAlpha3: string): string {
        const country = countries.find((country: any) => country.alpha3.toLowerCase() === countryCodeAlpha3.toLowerCase());

        if(!country) {
            return 'unknown_alpha2'.toUpperCase();
        }

        return country.alpha2.toUpperCase();
    }
};

export default CountryService;