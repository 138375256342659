import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import TRANSLATION_EN from '../res/lang/en.json';
import TRANSLATION_ES from '../res/lang/es.json';
import {DEFAULT_ISO_COUNTRY, DEFAULT_LANGUAGE} from '../config/constants';

const EN = 'en';
const ES = 'es';

export const getCountryLanguage = (country) => {
  switch (country) {
    case 'es_PE':
    case 'PE':
    case 'es_MX':
    case 'MX':
    case 'es_CO':
    case 'CO':
    case 'es_CL':
    case 'none':
    case 'CL':
      return ES;
    case 'en_US':
    case 'US':
      return EN;
    default:
      return ES;
  }
};

export const t = (value) => i18n.t(value);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: ['en_US', 'es_CL'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: TRANSLATION_EN
      },
      en_US: {
        translation: TRANSLATION_EN
      },
      es_CO: {
        translation: TRANSLATION_ES
      },
      es_CL: {
        translation: TRANSLATION_ES
      },
      es_MX: {
        translation: TRANSLATION_ES
      },
      es_PE: {
        translation: TRANSLATION_ES
      },
      es: {
        translation: TRANSLATION_ES
      },
    }
  });

export default i18n;

