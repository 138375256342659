import ApiResponse from "../models/ApiResponse";
import api from "../utils/axios";
import {formattingAxiosResponseErrors, formattingTitaskBffResponseErrors} from "../utils/utils";
import {Region} from "../models/Region";
import axios from "axios";
import {API_GEOLOCATION_URL, DEFAULT_COUNTRY} from "../config/constants";
import {StatusDataModel} from "../models/StatusDataModel";
import BffApiResponse from "../models/BffApiResponse";

const UtilService = {
    getTerritorialOrganizationByCountry : async (country: string) : Promise<ApiResponse> => {
        try {
            let rawData:any[] = [];

            switch (country) {
                default:
                    throw Error('Country not supported');
            }

            const data: Region[] = rawData.map((rawRegion: any): Region => {
                return {
                    regionId: rawRegion.id_region,
                    region: rawRegion.nombre_region,
                    provinceId: rawRegion.id_provincia,
                    province: rawRegion.nombre_provincia,
                    cityId: rawRegion.id_comuna,
                    city: rawRegion.nombre_comuna
                };
            });

            return {
                success: true,
                data: data
            };
        }catch (err)
        {
            return formattingAxiosResponseErrors(err);

        }
    },

    getLocalCountryFromApi : async () : Promise<string> => {
        try {
            const { data: { country: localCountry } } = await axios.get(API_GEOLOCATION_URL);
            return localCountry;
        } catch (error) {
            console.error(error)
            return DEFAULT_COUNTRY;
        }
    },

    getDataForStatusForCurrentUser : async () : Promise<BffApiResponse> => {
        try {
            const response = await api.get(`/user-utility/get-titask-data-for-status-by-current-user`);
            const responseData = response.data.data;
            return new BffApiResponse(true, responseData as StatusDataModel);
        }catch (err)
        {
            return formattingTitaskBffResponseErrors(err);

        }
    },
};

export default UtilService;
