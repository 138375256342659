import { Route as ReactRoute } from 'react-router';
import {Redirect, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect} from "react";

declare global {
    interface Window {
        _hsq: { push: (callParam: any) => void },
        hsConversationsSettings: any
    }
}

function Route({ component: Component , ...children } : any) : JSX.Element  {
    const user = useSelector((state : any) => state.user );
    const location = useLocation();

    useEffect(() => {
        if (user.id !== 0) {
            window._hsq.push(["setPath", location.pathname]);
            window._hsq.push(['trackPageView']);
        }
    }, [location]);

    return <ReactRoute {...children} render={props => <Component {...props} />} />;
}

export default Route;