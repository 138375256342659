import AppBar from "@material-ui/core/AppBar";
import {Container} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import LogoPng from "../../../res/img/logo.png";

import './LoadingNavbar.scss';

const LoadingNavbar = (): JSX.Element => {
    return (
        <div className="toolbar__main">
            <AppBar position="static">
                <Container maxWidth="sm">
                    <Toolbar className="toolbar__main__content">
                        <a>
                            <img className="toolbar__main__content__img" src={LogoPng} alt="logo" />
                        </a>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
};

export default LoadingNavbar;