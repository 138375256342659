import { useEffect } from 'react';
import { Navbar } from '../../Organisms/Navbar/Navbar';
import LoadingSpin from "../../Atoms/LoadingSpin/LoadingSpin";

import './App.scss';
import { Modal, ModalTransition, useModal } from 'react-simple-hook-modal';
import AuthService from 'services/AuthService';
import { useStore } from 'react-redux';
import BasicModal from 'components/Atoms/BasicModal/BasicModal';
import Text from 'components/Atoms/Text/Text';
import { Container } from '@material-ui/core';
import Button from 'components/Atoms/Button/Button';

interface AppInterface {
  children: React.ReactNode;
  loading?: boolean,
  loadingText?: string
};

const AppTemplate = ({
  children,
  loading,
  loadingText = ''
}: AppInterface) => {
  const store = useStore();
  const { isModalOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    let isAuth = true;
    const interval = setInterval(async () => {
      const verifySessionResponse = await AuthService.verifyUserSession(store);

      if (!verifySessionResponse.success) {
        isAuth = false;
        clearInterval(interval);
        openModal();
      }
    }, 2 * 60 * 1000);

    if (!isAuth) {
      clearInterval(interval);
    }
  }, []);

  const closeModalOnClick = () => {
    closeModal();
    window.location.href = '/';
  };

  return !loading ? (
    <div className="app__main">
      <Navbar />
      <div className="app__content">
        {children}
      </div>
      <Modal
        modalClassName="modal__main certifications__main"
        id="certifications-modal"
        isOpen={isModalOpen}
        transition={ModalTransition.SCALE}
      >
        <BasicModal title="Debes iniciar sesión" closeModal={closeModalOnClick}>
          <Text text="Hemos detectado que se ha iniciado sesión desde otro dispositivo, si desea continuar desde aquí por favor inicie sesión" />
          <div className="modal-button-container">
            <Container maxWidth="sm">
              <Button
                className="modal-button"
                text={'Iniciar sesión'}
                onClick={closeModalOnClick}
                type="primary"
              />
            </Container>
          </div>
        </BasicModal>
      </Modal>
    </div>
  ) : (
      <>
          <LoadingSpin loading={loading} loadingtext={loadingText}/>
      </>
  );
};

export default AppTemplate;