import {Container, Skeleton} from "@mui/material";
import React from "react";
import AppTemplate from "../../../components/Templates/App/App";
import LoadingAppTemplate from "../../../components/Templates/LoadingApp/LoadingApp";
import Title from "../../../components/Atoms/Title/Title";
import Text from "../../../components/Atoms/Text/Text";
import BackArrow from "../../../components/Atoms/BackArrow/BackArrow";

interface IIdentityVerificationLoadingSkeleton {
    withRealNavBar?: boolean
};

const IdentityVerificationLoadingSkeleton = ({ withRealNavBar }: IIdentityVerificationLoadingSkeleton): JSX.Element => {
    const Content = (): JSX.Element => {
        return (
            <Container maxWidth="sm">
                <BackArrow disabled={true}/>

                <Title
                    text={<Skeleton variant="text" width={'55%'} height={64} className="skeleton__identity__verification__loading__skeleton__title"/>}
                    type="primary"
                />
                <Text
                    text={
                        <>
                            <Skeleton variant="text" width={'90%'} height={20} />
                            <Skeleton variant="text" width={'30%'} height={20} />
                        </>
                    }
                />

                <Container maxWidth="sm">
                    <Skeleton variant="text" className="mt-20" width={'45%'} height={20} />
                    <div className="skeleton-center mb-20">
                        <Skeleton style={ { borderRadius: '5px' } } variant="rectangular" width={'100%'} height={35} />
                    </div>
                    <Skeleton variant="text" width={'45%'} height={20} />
                    <div className="skeleton-center">
                        <Skeleton style={ { borderRadius: '5px' } } variant="rectangular" width={'100%'} height={35} />
                    </div>

                    <div className="skeleton-center mt-30">
                        <Skeleton style={ { borderRadius: '12px' } } variant="rectangular" width={'100%'} height={35} />
                    </div>
                </Container>
            </Container>
        );
    };

    return (
        <>
            {
                withRealNavBar
                    ? (
                        <AppTemplate>
                            <Content />
                        </AppTemplate>
                    )
                    : (
                        <LoadingAppTemplate>
                            <Content />
                        </LoadingAppTemplate>
                    )
            }
        </>
    );
};

export default IdentityVerificationLoadingSkeleton;
