import Title from "../../../components/Atoms/Title/Title";
import {Container, Skeleton} from "@mui/material";
import Text from "../../../components/Atoms/Text/Text";
import React from "react";
import AppTemplate from "../../../components/Templates/App/App";
import LoadingAppTemplate from "../../../components/Templates/LoadingApp/LoadingApp";
import BackArrow from "../../../components/Atoms/BackArrow/BackArrow";

interface ICertificationQuizLoadingSkeleton {
    withRealNavBar?: boolean
};


const CertificationQuizLoadingSkeleton = ({ withRealNavBar }: ICertificationQuizLoadingSkeleton): JSX.Element => {
    const Content = (): JSX.Element => {
        return (
            <Container maxWidth="sm">
                <BackArrow disabled={true}/>

                <Text
                    text={
                        <>
                            <Skeleton variant="text" width={'75%'} height={24} />
                            <Skeleton className="mt-5 mb-20" variant="text" width={'100%'} height={14} />
                            <Skeleton variant="text" width={'100%'} height={20} />
                            <Skeleton variant="text" width={'45%'} height={20} />
                        </>
                    }
                />
                <Container maxWidth="sm">
                    <div className="skeleton-center">
                        <Skeleton variant="rectangular" width={'100%'} height={225} />
                    </div>

                    <div className="mt-30">
                        <Skeleton variant="text" width={'65%'} height={20} />
                    </div>

                    <div className="skeleton flex-container mt-10">
                        <Skeleton variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(100% - 45px)'} height={20} />
                        <Skeleton className="hidden" variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(60% - 58px)'} height={20} />
                        <Skeleton variant="text" className="hidden" width={'40%'} height={20} />
                    </div>

                    <div className="skeleton flex-container mt-10">
                        <Skeleton variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(100% - 45px)'} height={20} />
                        <Skeleton className="hidden" variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(60% - 58px)'} height={20} />
                        <Skeleton variant="text" className="hidden" width={'40%'} height={20} />
                    </div>

                    <div className="skeleton flex-container mt-10">
                        <Skeleton variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(100% - 45px)'} height={20} />
                        <Skeleton className="hidden" variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(60% - 58px)'} height={20} />
                        <Skeleton variant="text" className="hidden" width={'40%'} height={20} />
                    </div>

                    <div className="skeleton flex-container mt-10">
                        <Skeleton variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(100% - 45px)'} height={20} />
                    </div>

                    <div className="mt-30">
                        <Skeleton variant="text" width={'100%'} height={20} />
                        <Skeleton variant="text" width={'45%'} height={20} />
                    </div>

                    <div className="skeleton flex-container mt-10">
                        <Skeleton variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(100% - 45px)'} height={20} />
                        <Skeleton className="hidden" variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(60% - 58px)'} height={20} />
                        <Skeleton variant="text" className="hidden" width={'40%'} height={20} />
                    </div>

                    <div className="skeleton flex-container mt-10">
                        <Skeleton variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(100% - 45px)'} height={20} />
                        <Skeleton className="hidden" variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(60% - 58px)'} height={20} />
                        <Skeleton variant="text" className="hidden" width={'40%'} height={20} />
                    </div>

                    <div className="skeleton flex-container mt-10">
                        <Skeleton variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(100% - 45px)'} height={20} />
                        <Skeleton className="hidden" variant="circular" width={'25px'} height={20} />
                        <Skeleton variant="text" width={'calc(60% - 58px)'} height={20} />
                        <Skeleton variant="text" className="hidden" width={'40%'} height={20} />
                    </div>

                </Container>
            </Container>
        );
    };

    return (
        <>
            {
                withRealNavBar
                    ? (
                        <AppTemplate>
                            <Content />
                        </AppTemplate>
                    )
                    : (
                        <LoadingAppTemplate>
                            <Content />
                        </LoadingAppTemplate>
                    )
            }
        </>
    );
};

export default CertificationQuizLoadingSkeleton;