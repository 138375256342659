export enum Severity {
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success',
}

export default interface AlertMessage {
    open?: boolean;
    message: string;
    severity: Severity;
    translate: boolean;
}