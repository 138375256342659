import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import Routes from './routes/routes';
import LoadingScreen from "./components/Atoms/LoadingScreen/LoadingScreen";
import HelperAction from "./redux/reducers/helper/actions";
import i18n from "./utils/i18n";
import { useDispatch } from "redux-react-hook";
import setUpInterceptor from "./utils/interceptor";
import AuthService from "./services/AuthService";
import UserAction from "./redux/reducers/user/actions";
import * as dotenv from 'dotenv';
import 'moment/locale/es';
import CountryService from './services/CountryService';
import UtilService from "./services/UtilService";
import Country from "./models/Country";
import {CACHE_FILE_STORAGE, DEFAULT_LANGUAGE, HUBSPOT_WORKFLOW_CHAT_ID} from "./config/constants";

import 'react-simple-hook-modal/dist/styles.css';
import './assets/styles/global.scss';
import './App.scss';

declare global {
	interface Window {
		_hsq: { push: (callParam: any) => void },
		hsConversationsSettings: any
	}
}

const App = () => {
	const store = useStore();
	const dispatch = useDispatch();
	const [loadingInitValues, setLoadingInitValues] = useState(true);
	const authUser = useSelector((state: any) => state.user);
	const { localCountry } = useSelector((state: any) => state.helperReducer);
	dotenv.config({ path: '../.env' });

	setUpInterceptor(store);

	useEffect(() => {
		loadHubspotConfig();
		//Clear storage caché
		// localStorage.setItem(CACHE_FILE_STORAGE, JSON.stringify([]));

		//Hubspot scripts
		const hubspotScript = document.createElement("script");
		hubspotScript.src = `//js.hs-scripts.com/${HUBSPOT_WORKFLOW_CHAT_ID}.js`;
		hubspotScript.type = "text/javascript";
		hubspotScript.id = "hs-script-loader";
		hubspotScript.defer = true;
		hubspotScript.async = true;

		document.body.appendChild(hubspotScript);

		return () => {
			document.body.removeChild(hubspotScript);
		};
	}, []);

	useEffect(() => { init(); }, [dispatch, localCountry, store]);

	const countryFromApiResponse = async () => {
		const availableCountriesResponse = await CountryService.getAvailableCountries();
		let countriesAux = [];
		let localCountryAux: Country | undefined = undefined;

		if (availableCountriesResponse.success) {
			countriesAux = availableCountriesResponse.data;

		}else{
			console.error(availableCountriesResponse.errorMessage);
		}
		dispatch(HelperAction.save({ countries: countriesAux, localCountry: localCountry }));

		if (!localCountry?.code) {
			const localCountryCode = await UtilService.getLocalCountryFromApi();
			localCountryAux =  countriesAux.find((country: Country) => country.code.toUpperCase() === localCountryCode.toUpperCase());
			dispatch(HelperAction.save({ countries: countriesAux, localCountry: localCountryAux as Country }));
		}

		if (authUser.id === 0) {
			await i18n.changeLanguage(localCountryAux?.language || DEFAULT_LANGUAGE);
		}
		else {
			await i18n.changeLanguage(authUser.language);
		}
	}

	const verifyUserSession = async () => {

		const user = await AuthService.verifyUserSession(store);

		if(user.success)
		{
			dispatch(UserAction.save(user.data));
			window._hsq.push(["identify", { email: user.data.email }]);
			loadHubspotConfig(user.data);
		} else {
			dispatch(UserAction.remove({}));
		}
	}

	const loadHubspotConfig = (user?: any) => {
		if(user) {
			window.hsConversationsSettings = {
				loadImmediately: false,
				enableWidgetCookieBanner: false,
				disableAttachment: false,
				identificationEmail: user.email,
				identificationToken: user.hubspotVisitorToken || ''
			};
		}else{
			window.hsConversationsSettings = {
				loadImmediately: false,
				enableWidgetCookieBanner: false,
				disableAttachment: false
			};
		}
	};

	const init = async () => {
		await verifyUserSession();
		await countryFromApiResponse();
		setLoadingInitValues(false);
	};

	return (
		<>
			{
				!loadingInitValues ? <Routes auth={authUser} /> : <LoadingScreen />
			}
		</>
	);
};

export default App;
