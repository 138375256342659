export default class BffApiResponse<T = any> {
    public success: boolean;
    public data: T;
    public statusCode: number;
    public errorMessage: string;

    constructor(success: boolean, data: any, statusCode = 200, errorMessage = '') {
        this.success = success;
        this.data = data;
        this.statusCode = statusCode;
        this.errorMessage = errorMessage;
    }
}