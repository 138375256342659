import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Container } from "@mui/material";
import { useHistory } from 'react-router-dom';

import './BackArrow.scss';

interface BackArrowInterface {
  onClick?: () => void,
  disabled?: boolean
};

const BackArrow = ({
  onClick,
  disabled = false
}: BackArrowInterface) => {
  const history = useHistory();

  const goBack = () => {
    if(!disabled) {
      if (onClick) {
        onClick();
      }
      else {
        history.goBack();
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <ArrowBackIosIcon className={`go-back-arrow ${(disabled) ? 'MuiSvgIcon__disabled' : ''}`} onClick={goBack} />
    </Container>
  );
};

export default BackArrow;