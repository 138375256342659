import { Button as MaterialButton, Container } from "@material-ui/core";
import { Link } from "react-router-dom";

import './Button.scss';

interface ButtonInterface {
  backgroundColor?: string;
  component?: any;
  className?: string;
  disabled?: boolean;
  endIcon?: any;
  fromModal?: boolean;
  large?: boolean;
  linkTo?: string;
  onClick?: (e: any) => void;
  size?: "large" | "medium" | "small" | undefined;
  submit?: boolean;
  startIcon?: any;
  text: string | React.ReactNode;
  type?: string;
  titleButton?: string;
  form?: string;
};

const Button = ({
  backgroundColor,
  component = 'button',
  className = '',
  disabled = false,
  endIcon = null,
  fromModal = false,
  large = false,
  linkTo = '',
  onClick,
  size = 'medium',
  submit = false,
  startIcon = null,
  text,
  titleButton='',
  type = 'primary',
  form = '',
}: ButtonInterface) => {

  const ButtonComponent = () => (
    <MaterialButton
      component={component}
      className={`btn
        ${type === 'primary' ? ' btn--primary ' : ' btn--secondary '}
        ${className }${backgroundColor ? ` btn--${backgroundColor} ` : ''}
        ${disabled ? ' btn--disabled ' : ''} btn--${size}
        ${fromModal ? ' btn--from-modal ' : ''}`}
      style={{ backgroundColor }}
      onClick={onClick}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      type={submit ? "submit" : 'default'}
      variant="contained"
      title={titleButton}
      {...(form ? { form } : null)}
    >
      {text}
    </MaterialButton>
  );

  const IsLarge = () => large ? (
    <ButtonComponent />
  ) : (
    <Container maxWidth="sm">
      <ButtonComponent />
    </Container>
  );

  return linkTo ? (
    <Link to={linkTo}>
      <IsLarge />
    </Link>
  ) : (
    <IsLarge />
  );
};

export default Button;