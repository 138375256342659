import { combineReducers } from 'redux';
import userReducer from './user/reducer';
import alertMessageReducer from './alertMessage/reducer';
import helperReducer from './helper/reducer';
import formDataPorfile from './porfile/reducer';

const rootReducer = combineReducers({
	user: userReducer,
	alert: alertMessageReducer,
	helperReducer: helperReducer,
	formDataPorfile: formDataPorfile
});

export default rootReducer;
