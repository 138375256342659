import api from "../utils/axios";
import ApiResponse from "../models/ApiResponse";
import User from "../models/User";
import {formattingAxiosResponseErrors, formattingTitaskBffResponseErrors} from "../utils/utils";
import {CertificateSubscription} from "../models/CertificateSubscription";
import Profile from "../models/Profile";
import City from "../models/City";
import BffApiResponse from "../models/BffApiResponse";
import {CACHE_FILE_STORAGE} from "../config/constants";
import {CacheFile} from "../models/CacheFile";
import DocumentType from "../models/DocumentType";

const AuthService = {
    verifyUserSession : async ( store : any) : Promise<ApiResponse> => {

        //Todo: check
        if(!store.getState().user.token)
        {
            return {
                success: false,
                error: "Don't have a token"
            };
        }

        try {
            const response = await api.get(`/auth/current-user`);
            const userData = response.data.data;
            let user: User | null = null;

            if(userData) {
                let profile: Profile | undefined = undefined;

                if(userData.profile) {

                    let city: City | undefined = undefined;

                    if(userData.profile.city) {
                        city = {
                            id: userData.profile.city.id,
                            regionId: userData.profile.city.regionId,
                            regionName: userData.profile.city.regionName,
                            provinceId: userData.profile.city.provinceId,
                            provinceName: userData.profile.city.provinceName,
                            cityId: userData.profile.city.cityId,
                            cityName: userData.profile.city.cityName
                        };

                        profile = {
                            id: userData.profile.id,
                            name: userData.profile.name,
                            lastname: userData.profile.lastname,
                            validatedFullname: userData.profile.validatedFullname,
                            documentType: userData.profile.documentType,
                            documentNumber: userData.profile.documentNumber,
                            gender: userData.profile.gender,
                            phone: userData.profile.phone,
                            birthDate: userData.profile.birthDate,
                            nationality: userData.profile.nationality,
                            validatedInformationAt: userData.profile.validatedInformationAt,
                            city: city,
                            address: userData.profile.address,
                            metamapPayload: userData.profile.metamapPayload
                        }
                    }
                }
                user = {
                    id: userData.id,
                    email: userData.email,
                    countryCode: userData.countryCode,
                    token: userData.token,
                    hubspotVisitorToken: userData.hubspotVisitorToken,
                    avatarBase64Encoded: userData.avatarS3Key,
                    profile: profile,
                    countMessage: userData.countMessage
                };
            }

            return {
                success: true,
                data: user,
            };
        }catch (err)
        {
            return formattingAxiosResponseErrors(err);
        }
    },
    logout : async () : Promise<ApiResponse> => {
        try {
            const response = await api.post(`/auth/logout`);
            console.log(response);
            return {
                success: true
            };
        }catch (err)
        {
            return formattingAxiosResponseErrors(err);

        }
    },
    getFileByKey : async (key: string, useCache: boolean = true): Promise<string> => {
        let fileEncoded = '';
        let cacheItem = localStorage.getItem(CACHE_FILE_STORAGE) || '[]';
        let currentFiles: CacheFile[] = JSON.parse(cacheItem);

        const file = currentFiles.find((file: CacheFile) => file.s3Key === key);

        if(file){
            return file.fileEncoded;
        }else {
            const response = await api.post("/file", { key });
            fileEncoded = response.data.data?.base64Encoded || '';
        }

        if(useCache) {
            cacheItem = localStorage.getItem(CACHE_FILE_STORAGE) || '[]';
            let currentFiles: CacheFile[] = JSON.parse(cacheItem);
            currentFiles.push({s3Key: key, fileEncoded});
            localStorage.setItem(CACHE_FILE_STORAGE, JSON.stringify(currentFiles));
        }

        return fileEncoded;
    },
    getCurrentProfile: async (): Promise<BffApiResponse> => {
        try {
            const response = await api.get(`/profile/current-user-profile`);
            const profileData = response.data.data;
            let profile: Profile | null = null;

            if(profileData) {
                let city: City | undefined = undefined;

                if(profileData.city) {
                    city = {
                        id: profileData.city.id,
                        regionId: profileData.city.regionId,
                        regionName: profileData.city.regionName,
                        provinceId: profileData.city.provinceId,
                        provinceName: profileData.city.provinceName,
                        cityId: profileData.city.cityId,
                        cityName: profileData.city.cityName
                    };

                    profile = {
                        id: profileData.id,
                        name: profileData.name,
                        lastname: profileData.lastname,
                        validatedFullname: profileData.validatedFullname,
                        documentType: profileData.documentType,
                        documentNumber: profileData.documentNumber,
                        gender: profileData.gender,
                        phone: profileData.phone,
                        birthDate: profileData.birthDate,
                        nationality: profileData.nationality,
                        validatedInformationAt: profileData.validatedInformationAt,
                        city: city,
                        address: profileData.address,
                        metamapPayload: profileData.metamapPayload
                    }
                }
            }
            return new BffApiResponse(true, profile);
        }catch (err)
        {
            return formattingTitaskBffResponseErrors(err);
        }
    },
    getDocumentByKey: async (key: string, countryCode: string): Promise<BffApiResponse> => {
        try {
            const response = await api.post(`/document-type/${key}`, { countryCode });
            const dataRaw = response.data.data;
            const documentType: DocumentType = {
                key: dataRaw.key,
                countryCode: dataRaw.countryCode,
                nameEs: dataRaw.nameEs,
                nameEn: dataRaw.nameEn,
                description: dataRaw.description,
                isActive: dataRaw.isActive
            };

            return new BffApiResponse(true, documentType);
        }catch (err)
        {
            return formattingTitaskBffResponseErrors(err);
        }
    },
};

export default AuthService;
